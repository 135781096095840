import axios from 'axios';
import { useEffect } from 'react';
import { ContextProvider } from './components/context/context';
import Routes from './Routes';
import { GlobalStyle } from './styles/Global.styles';

function App() {

  useEffect(() => {
    const favicon = document.getElementById('favicon');
    //@ts-ignore
    favicon.href = process.env.REACT_APP_PAGE_FAVICON ?? '';
    document.title = process.env.REACT_APP_PAGE_TITLE ?? '';
  }, []);
  return (
    <>
      <ContextProvider>
        <GlobalStyle />
        <Routes />
      </ContextProvider>
    </>
  );
}

export default App;

import { useState, useRef } from 'react';
import { Footer } from '../../../shared/footer';
import { Button } from '../../../shared/formElements/button';
import { Header } from '../../../shared/header';
import * as Yup from 'yup';
import {
  Form,
  FormContainer,
  FormSectionContainer,
  PageLayoutContainer,
  SectionContainer,
  ButtonGroupContainer,
  LoadingSpinner,
  ImageSplitFormSection,
  Line,
} from '../../../shared/Layout.styles';
import { Markdown } from '../../../shared/markdown';
import { balanceConstants } from '../../../utils/constants/balance';
import { activateCardConstants } from '../../../utils/constants/activate';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../../../shared/formElements/input';
import { BalanceTable } from './sections/BalanceTable';
import { Transaction, useContextDispatch, useContextState } from '../../context/context';
import axios from 'axios';
import { IconSectionCard } from '../../../shared/iconSectionCardSection';
import { globalConstants } from '../../../utils/constants/global';
import isEmpty from "lodash/isEmpty";
import ReCAPTCHA from "react-google-recaptcha";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const { activationCode } = activateCardConstants.validations;
var captchaToken: string
var currentPage: number = 0
var leastRecent: Date
var mostRecent: Date
var prevActivationCode: string
var searchUntil: Date

export const Balance = () => {
  const dispatch = useContextDispatch();
  const { transactions, loading, balance, hasMore} = useContextState();

  const [serverErrors, setServerErrors] = useState("");
  const [showBalance, setShowBalance] = useState(false);
  const [isCaptchaCompleted, setCaptchaCompleted] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);

  const { heading, subHeading, balanceText, balanceButtonText, defaultValue } =
    balanceConstants;
  const { expiredCardMessage } = globalConstants;

  const recaptcha = useRef<ReCAPTCHA>(null);

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
    defaultValues: {
      activationCode: "",
    },
  });


  // cardNumber: 'VLF8023QB', dummydata
  const onSubmit = async (formData: any) => {
    if (isCaptchaCompleted && !isFormSubmitted){
      setFormSubmitted(true)
      try { 
        setServerErrors("");
        dispatch('START_LOADING');
        const data = {
          captchaToken: captchaToken,
          cardNumber: formData.activationCode
        };
        if (currentPage === 0){
          mostRecent = new Date();
          currentPage++;
          leastRecent=new Date(mostRecent)
          leastRecent.setDate(leastRecent.getDate() - 180);
        }

        const isLoadingMore = prevActivationCode == formData.activationCode
        const params = {
          start_date: leastRecent, 
          end_date: mostRecent, 
          page_size: isLoadingMore ? 200 : 100, // increased from 100
          page_number: currentPage
        }
        searchUntil = new Date(leastRecent) // have to new Date() otherwise copied by reference
        const res = await axios.post('/CardTransactions', data, {params});
        const resStatus = await axios.post('/CardStatus', data);
  
        dispatch('SET_BALANCE_TOTAL', resStatus.data.balance);
        var transactionResponse = res.data
        var tran: Transaction[] = []
        
        //remove duplicate from the top of the list.
        if (transactions) {
          tran = transactionResponse.slice(1);
        } else if (!transactions) {
          tran = transactionResponse
        }


        //The oldest transaction in the most recent response
        var lastTran = tran.length !== 0 
        ? res.data.at(-1) 
        : null
        
        var lastTranDate = new Date(lastTran?.occurred_at)


        // if there is a first transaction prop, set the date to that
        var firstTransactionDate = resStatus.data?.first_transaction_occurred_at 
        ? new Date(resStatus.data.first_transaction_occurred_at)
        : lastTranDate


        if (firstTransactionDate < leastRecent || transactionResponse.length == params.page_size) {
          dispatch("HAS_MORE")

          // if no transactions in last 180 days 
          if (tran.length === 0) {
            lastTranDate = new Date(leastRecent)
          }
          
          // shift to 180 days from oldest shown transaction
          searchUntil = lastTranDate
          mostRecent = lastTranDate
          leastRecent.setDate(lastTranDate.getDate() - 180)
        } else {
          dispatch("NO_MORE")
        }

  
        // if using the same EAID as last time append new transactions
        if(isLoadingMore) {
          dispatch('ADD_BALANCES',tran)
        } else {
          dispatch('SET_BALANCES',tran)
        }

        prevActivationCode = formData.activationCode
        setShowBalance(true);
        dispatch('STOP_LOADING')

        // reset the recaptcha status
        recaptcha.current?.reset()
        onChange("")

      } catch (err:any) {
        if (err.response.status === 404) {
          setServerErrors("Card Not Found");
          console.log("err", err);
        } else if (err.response.status === 500) {
          setServerErrors(err.response.data.message);
        } else if (err.response.status === 700) {
          setServerErrors(expiredCardMessage);
        } else {
          setServerErrors('Card Not Found');
        }
        dispatch("STOP_LOADING");
      }
      setFormSubmitted(false)
    } else {
      if (!isCaptchaCompleted) {
        setServerErrors('Captcha not verified');
      }
    }
  };

  function onChange(value: any) {
    setCaptchaCompleted(Boolean(value));
    captchaToken = value;
    console.log("Captcha value:", value);
  }
    
  const getMore = () => {
    onSubmit({activationCode: prevActivationCode})
  }

  const {
    control,
    formState: { errors },
  } = methods;

  return (
    <>
      <Header />
      <PageLayoutContainer>
        <Line />
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <SectionContainer>
              <Markdown children={heading} />
              {!showBalance && (
                <Markdown children={subHeading} className="balanceText" />
              )}
                <FormContainer>
                  {showBalance ? (
                    <BalanceTable 
                      transactions={transactions} 
                      balance={balance} 
                      hasMore={hasMore} 
                      getMore={getMore} 
                      loading={loading} 
                      searchUntil={searchUntil} 
                      onChange={onChange} 
                      recaptcha={recaptcha}
                      serverErrors={serverErrors}
                  />                
                 ) : (
                  <>
                    <FormSectionContainer className="balanceSection">
                      <Controller
                        render={(props) => (
                          <Input
                            {...props}
                            type="text"
                            label="Claim Activation Code*"
                            fieldName="activationCode"
                            validation={
                              errors?.activationCode?.message || serverErrors
                            }
                            width="100%"
                          />
                        )}
                        name="activationCode"
                        control={control}
                        defaultValue={defaultValue.activationCode || ''}
                      />
                      <Markdown children={balanceText} className="subText" />
                      <ReCAPTCHA
                        ref={recaptcha}                        
                        sitekey="6LfU8UspAAAAAGYvuYdOkXrHcGGpuEXM4-hxH7Rh"
                        onChange={onChange}
                      />
                      <ButtonGroupContainer>
                        <Button
                          onClick={() => {
                            methods.handleSubmit(onSubmit);
                          }}
                        >
                          {loading ? (
                            <LoadingSpinner show />
                          ) : (
                            balanceButtonText
                          )}
                        </Button>
                      </ButtonGroupContainer>
                    </FormSectionContainer>
                    <FormSectionContainer>
                      {process.env.REACT_APP_CARD_OR_ICONS === 'CARD' 
                        ? process.env.REACT_APP_SHOW_CARD_ARTWORK === "true" ? (
                            <ImageSplitFormSection
                              src={process.env.REACT_APP_CARD_FRONT ?? ''}
                            />
                        ) : ("") : (
                        <IconSectionCard />
                    )}
                    </FormSectionContainer>
                  </>
                )}
              </FormContainer>
            </SectionContainer>
            <Footer />
          </Form>
        </FormProvider>
      </PageLayoutContainer>
    </>
  );
};
//
const validationSchema = Yup.object().shape({
  activationCode: Yup.string()
    .required(activationCode.required)
    .matches(/^[a-zA-Z0-9_.-]*$/, {
      message: activationCode.format,
      excludeEmptyString: true,
    }),
});

import { Footer } from '../../../shared/footer';
import { Button } from '../../../shared/formElements/button';
import { Header } from '../../../shared/header';
import {
  ButtonGroupContainer,
  Line,
  PageLayoutContainer,
  SectionContainer,
} from '../../../shared/Layout.styles';
import { Markdown } from '../../../shared/markdown';
import { termsConstants } from '../../../utils/constants/terms';

export const Terms = () => {
  const {
    subHeading,
    termsInstructionsText,
    termsButtonText,
    vaultTermsText,
  } = termsConstants;

  return (
    <>
      <Header />
      <PageLayoutContainer>
        <Line />
        <SectionContainer>
          <Markdown children={subHeading} align="center" />
          {process.env.REACT_APP_TERMS_TEXT !== 'null' ? (
            <Markdown children={vaultTermsText} className="vaultTermsText" />
          ) : (
            <>
              <Markdown
                children={termsInstructionsText}
                align="center"
                className="termsInstructionsText"
              />
              <ButtonGroupContainer align="center">
                <Button
                  onClick={() =>
                    window.location.replace(
                      process.env.REACT_APP_TERMS_LINK ?? ''
                    )
                  }
                  children={termsButtonText}
                />
              </ButtonGroupContainer>
            </>
          )}
        </SectionContainer>
        <Footer />
      </PageLayoutContainer>
    </>
  );
};

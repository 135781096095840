export const globalConstants = {
    footerLinks: [
        { link: `Activate Card`, url: '/activate' },
        { link: `Check Balance`, url: '/balance' },
        { link: `Terms & Conditions`, url: '/terms' },
    ],
    disclaimer: `*Purchase by 4:59pm AEST 31/08/21. Claim by 4:59pm AEDT 29/10/21. Domestic and residential purchases only. Eligible models only, see website for list. Exclusions apply, see full terms and conditions. Proof of purchase and payment required. Customers will need their outdoor model and serial numbers and indoor model number to complete their claim. Full terms and conditions available at fujitsugeneral.com.au/promotions. The Fujitsu Prepaid Mastercard is issued by EML Payment Solutions Limited ABN 30 131 436 532 AFSL 404131 pursuant to license by Mastercard Asia/Pacific Pte. Ltd. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated. Mastercard prepaid cards can be used at all merchants that accept Mastercard prepaid cards. Terms and conditions apply. Promoter is Fujitsu General (Aust.) Pty Limited (ABN 55 001 229 554) of 1 Telopea Place, Eastern Creek NSW 2766. `,
    iconLabel1: '## 1. Receive',
    iconLabel2: '## 2. Activate',
    iconLabel3: '## 3. Spend',
    expiredCardMessage: `Unfortunately the activation period on your card has expired. Per the instructions accompanying the card, your card needed to be activated within the specified timeframe. Click here (${process.env.REACT_APP_TERMS_LINK}) for full terms and conditions`,

}
import { useHistory } from 'react-router';
import { Footer } from '../../../shared/footer';
import { Button } from '../../../shared/formElements/button';
import { Header } from '../../../shared/header';
import * as Yup from 'yup';
import {
  Form,
  FormContainer,
  FormSectionContainer,
  ButtonGroupContainer,
  PageLayoutContainer,
  SectionContainer,
  LoadingSpinner,
  Line,
  ImageSplitFormSection,
} from '../../../shared/Layout.styles';
import { Markdown } from '../../../shared/markdown';
import {
  activateCardConstants,
  phoneMaxLength,
} from '../../../utils/constants/activate';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContextDispatch, useContextState } from '../../context/context';
import { ActivateCardInputs } from './sections/ActivateCardInputs';
import { useState } from 'react';
import { IconSectionCard } from '../../../shared/iconSectionCardSection';
import axios from 'axios';
import { globalConstants } from '../../../utils/constants/global';
const {
  activationCode,
  email,
  firstName,
  lastName,
  phoneNumber,
  termsConditionsCheck,
} = activateCardConstants.validations;

export const ActivateCard = () => {
  const dispatch = useContextDispatch();
  const { loading } = useContextState();

  const [serverErrors, setServerErrors] = useState('');

  const {
    heading,
    subHeading,
    activateCardText,
    activateCardButtonText,
  } = activateCardConstants;
  const { expiredCardMessage } = globalConstants;

  const { push } = useHistory();

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
    defaultValues: {
      activationCode: '',
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      termsConditionsCheck: false,
    },
  });

  const onSubmit = async (formData: any) => {
    try {
      dispatch('START_LOADING');
      const data = {
        fname: formData.firstName,
        lname: formData.lastName,
        eaid: formData.activationCode,
        email: formData.email,
        phone: formData.phoneNumber,
      };
      await axios.post('/ActivateCard', data);
      dispatch('STOP_LOADING');
      push('/success');
    } catch (err: any) {
      console.log('err', err);
      if (err.response.status === 400) {
        setServerErrors(err.response.data.message);
      } else if (err.response.status === 700) {
        setServerErrors(expiredCardMessage);
      }
      dispatch('STOP_LOADING');
    }
  };

  return (
    <>
      <Header />
      <PageLayoutContainer>
        <Line />
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <SectionContainer>
              <Markdown children={heading} />
              <Markdown children={subHeading} />
              <Markdown children={activateCardText} />
              <FormContainer>
                <FormSectionContainer className="activateCardSection">
                  <ActivateCardInputs />
                  {serverErrors && (
                    <Markdown
                      children={serverErrors}
                      className="validationText"
                    />
                  )}
                  <ButtonGroupContainer>
                    <Button
                      onClick={() => {
                        methods.handleSubmit(onSubmit);
                      }}
                    >
                      {loading ? (
                        <LoadingSpinner show />
                      ) : (
                        activateCardButtonText
                      )}
                    </Button>
                  </ButtonGroupContainer>
                </FormSectionContainer>
                <FormSectionContainer>
                  {process.env.REACT_APP_CARD_OR_ICONS === 'CARD' 
                    ? process.env.REACT_APP_SHOW_CARD_ARTWORK === "true" ? (
                        <ImageSplitFormSection
                          src={process.env.REACT_APP_CARD_FRONT ?? ''}
                        />
                    ) : ("") : (
                    <IconSectionCard />
                  )}
                </FormSectionContainer>
              </FormContainer>
            </SectionContainer>
            <Footer />
          </Form>
        </FormProvider>
      </PageLayoutContainer>
    </>
  );
};
const validationSchema = Yup.object().shape({
  activationCode: Yup.string()
    .required(activationCode.required)
    .matches(/^[a-zA-Z0-9_.-]*$/, {
      message: activationCode.format,
      excludeEmptyString: true,
    }),
  firstName: Yup.string()
    .required(firstName.required)
    .matches(/^([A-Za-z\s\-'".])*$/, {
      message: firstName.format,
      excludeEmptyString: true,
    }),
  lastName: Yup.string()
    .required(lastName.required)
    .matches(/^([A-Za-z\s\-'".])*$/, {
      message: lastName.format,
      excludeEmptyString: true,
    }),
  email: Yup.string().email(email.format).required(email.required),
  phoneNumber: Yup.string()
    .required(phoneNumber.required)
    .matches(/^(0)[0-9]+$/, {
      message: phoneNumber.format,
      excludeEmptyString: true,
    })
    .min(phoneMaxLength, phoneNumber.minLength)
    .max(phoneMaxLength, phoneNumber.maxLength),
  termsConditionsCheck: Yup.bool().oneOf([true], termsConditionsCheck.required),
});

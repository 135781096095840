export const phoneMaxLength = 10;


export const activateCardConstants = {
    heading: `# Activate your card`,
    subHeading: `### Please fill out the details below to activate your card`,
    activateCardText: `${process.env.REACT_APP_ACTIVATE_CARD_TEXT}`,
    activationCodeSubText: `Please note that your claim activation code is located at the back of the card and be sure to not include any spaces.`,
    phoneNumberSubText: `${process.env.REACT_APP_PHONE_SUB_TEXT}`,
    activateCardButtonText: `Activate Now`,
    termsConditionsCheckValue: 'true',
    termsConditionsText: `I understand and agree to the gift card [Terms & Conditions*](${process.env.REACT_APP_TERMS_LINK})`,
    defaultValue: {
        activationCode: '',
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        termsConditionsCheck: false,
    },
    validations: {
        activationCode: {
            id: 'activationCode',
            required: 'Claim Activation Code is required',
            format: `Claim Activation Code is invalid`,
        },
        email: {
            id: 'email',
            required: 'Email address is required',
            format: 'Email address is invalid',
        },
        firstName: {
            id: 'firstName',
            required: 'First given name is required',
            format: `First given name is invalid`,
        },
        lastName: {
            id: 'lastName',
            required: 'Last name is required',
            format: `Last name is invalid`,
        },
        phoneNumber: {
            id: 'phoneNumber',
            required: 'Mobile number is required',
            format: 'Mobile number must start with 0',
            minLength: `Mobile number must be ${phoneMaxLength} digits`,
            maxLength: `Mobile number must be ${phoneMaxLength} digits`,
        },
        termsConditionsCheck: {
            id: 'termsConditionsCheck',
            required: 'Terms & Conditions is required',
        },
    }
}
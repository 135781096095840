import styled from 'styled-components/macro';
import { device } from '../../styles/devices';

export const IconSectionContainer = styled.div`
  width: 100%;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  @media ${device.mobileMed} {
    flex-direction: row;
    max-width: 600px;
    margin: 0 auto;
    padding: 50px 10px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & svg {
    fill: white;
    margin: 0 auto;
    font-size: 100px;
  }
`;

import { ImageHeader } from '../Layout.styles';
import { HeaderContainer, BannerContainer, Banner } from './Header.styles';
import { useHistory } from 'react-router-dom';
import { HeaderLogo } from '../../images/svg';

export const Header = () => {
  const { push } = useHistory();

  return (
    <HeaderContainer>
      <BannerContainer>
        {process.env.REACT_APP_BANNER !== 'null' ? (
          <>
            <Banner
              onClick={() => push('/')}
              src={process.env.REACT_APP_BANNER ?? ''}
              alt="Banner Image"
            />
            <HeaderLogo push={push} />
          </>
        ) : (
          <>
            <ImageHeader
              src={process.env.REACT_APP_HEADER_LOGO ?? ''}
              onClick={() => push('/')}
            />
          </>
        )}
      </BannerContainer>
    </HeaderContainer>
  );
};

import styled from 'styled-components/macro';
import TextField from '@material-ui/core/TextField';
import {
  grey,
  fontFamily,
  mainFontFamily,
  inputBorder,
  inputBorderOnFocusColor,
  inputBorderOnHoverColor,
  inputTextColor,
} from '../../../styles/theme';

export interface IInputContainerProps {
  width?: '25%' | '50%' | '75%' | '100%';
}
export const InputContainer = styled.div<IInputContainerProps>`
  padding: 10px 0;
  width: ${({ width }) =>
    width === '25%'
      ? '25%'
      : width === '50%'
      ? '50%'
      : width === '75%'
      ? '75%'
      : width === '100%'
      ? '100%'
      : 'inherit'};
`;

export const InputElement = styled(TextField)`
  width: 100%;
  padding: 10px;

  .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.primary.default};
    font-family: ${mainFontFamily}, ${fontFamily};
  }

  .MuiInputBase-input {
    font-size: 14px;
    color: black;
  }
  .MuiOutlinedInput-input {
    padding: 14px;
    color: ${inputTextColor};
  }
  .MuiInputLabel-outlined {
    font-size: 14px;
    transform: translate(15px, 15px);
    font-family: ${mainFontFamily}, ${fontFamily};
    color: ${grey};
  }
  .MuiOutlinedInput-notchedOutline {
    border-radius: 0;
    border-color: ${inputBorder};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${inputBorderOnFocusColor};
  }
  .MuiFormLabel-root.Mui-error {
    color: ${grey};
  }

  &:hover {
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${inputBorderOnHoverColor};
    }
  }
  .MuiFormLabel-root.MuiOutlinedInput-input {
    color: ${inputBorderOnHoverColor};
  }
`;
